import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

// ***************************************  GUARDS 

const hasSubscriptionGuard = async (to, from, next) => {
  return new Promise(() => {
    const auth = store.getters['auth/currentSubscription']
    if (auth && store.state.auth.user.subscription.status < 2) {
      next()
    } else {
      next({ name: 'no-access' })
    }
  })
}
const hasPremiumGuard = async (to, from, next) => {
  return new Promise(() => {
    const auth = store.getters['auth/currentSubscription']
    if (auth && store.state.auth.user.subscription.status < 2 && store.state.auth.user.subscription.type == 1) {
      next()
    } else {
      next({ name: 'no-access' })
    }
  })
}
// **************************************************************

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        requiresAuth: true 
      },
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/Partners.vue'),
      meta: {
        pageTitle: 'Colaboradores',
        requiresAuth: true 
        
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        requiresAuth: true 
      },      
    },    
    {
      path: '/promotions',
      name: 'promotions',
      beforeEnter: hasPremiumGuard,      
      component: () => import('@/views/Promotions.vue'),
      meta: {
        pageTitle: 'Promociones',
        requiresAuth: true 
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/Contact.vue'),
      meta: {
        pageTitle: 'Contactar',
        requiresAuth: true 
      },
    },
    
    // ********************************************************** PEDIDOS 
    {
      path: '/orders',
      name: 'orders',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Orders.vue'),
      meta: {
        pageTitle: 'Mis Pedidos',
        requiresAuth: true 
      },
    },
    {
      path: '/order',
      name: 'order',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Order.vue'),
      meta: {
        pageTitle: 'Pedido',
        requiresAuth: true 
      },
    },
    {
      path: '/create',
      name: 'create',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Create.vue'),
      meta: {
        requiresAuth: true 
      },
    },
    {
      path: '/vdivisions',
      name: 'vdivisions',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Vdivisions.vue'),
      meta: {
        // layout: 'full',
        requiresAuth: true 
      },      
    },
    {
      path: '/hdivisions',
      name: 'hdivisions',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Hdivisions.vue'),
      meta: {
        // layout: 'full',
        requiresAuth: true 
      },      
    },
    {
      path: '/dessign',
      name: 'dessign',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Dessign.vue'),
      meta: {
        // layout: 'full',
        requiresAuth: true 
      },      
    },
    {
      path: '/elements',
      name: 'elements',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Elements.vue'),
      meta: {
        // layout: 'full',
        requiresAuth: true 
      },      
    },
    {
      path: '/sumary',
      name: 'sumary',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Sumary.vue'),
      meta: {
        // layout: 'full',
        requiresAuth: true 
      },      
    },
    // *************************************************************       Editar Pedido
    {
      path: '/order_edit',
      name: 'order_edit',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/edit.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true 
      },      
    },
    {
      path: '/elements_edit',
      name: 'elements_edit',
      beforeEnter: hasSubscriptionGuard,
      component: () => import('@/views/Elements_edit.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true 
      },      
    },
    // *************************************************************       Miscelania No AUth
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },    
    {
      path: '/politica-cookies',
      name: 'politicaCookies',
      component: () => import('@/views/politicaCookies.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/politica-privacidad',
      name: 'politicaPrivacidad',
      component: () => import('@/views/politicaPrivacidad.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/politica-desistimiento',
      name: 'politicaDesistimiento',
      component: () => import('@/views/politicaDesistimiento.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/registerOk',
      name: 'registerOk',
      component: () => import('@/views/RegisterOk.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recover',
      name: 'recover',
      component: () => import('@/views/Recover.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/recoverOk',
      name: 'recoverOk',
      component: () => import('@/views/RecoverPassOk.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: () => import('@/views/error/SinPermisos.vue'),
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


router.beforeEach(async (to, from, next) => {
  await store.dispatch("auth/getUser")
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/currentUser']) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
